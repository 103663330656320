import React, { useEffect, useState, useContext } from 'react';
import AppLayout from '../../../layout/AppLayout';
import { RootContext } from '../../../contextApi';
import SearchedHospitals from './components/SearchedHospitals';
import SearchHospital from './components/SearchHospital';
import HospitalTypeFilters from '../Doctor/components/filters/HospitalTypeFilters';
import CategoriesFilter from './components/filters/CategoriesFilters';
import InsuranceFilters from './components/filters/InsuranceFilters';
import AddonsFilter from './components/filters/AddonsFilters';
import { connect } from 'react-redux';
import { filterHospitals, insurancesFilter, categoriesFilter, hospitalTypesFilter, addonsFilter, clearHospitalSearch, searchHospitalByText } from '../../../store/actions/patient/searchedHospitalsActions';
import { useTranslation } from "react-i18next";
import { BeatLoader } from "react-spinners";
import { FadeLoading } from '../../../loaders';

function Hospital({ searchHospitalByText, clearHospitalSearch, searchedHospitals, filterHospitals, insurancesFilter, categoriesFilter, hospitalTypesFilter, addonsFilter }) {
    const { searchedHospitals: allSearchedHospitals, filters: { checkedInsurances, checkedCategories, hospitalTypes, checkedAddons } } = searchedHospitals && searchedHospitals;
    const [searchText, setSearchText] = useState("");
    const [loading, setLoading] = useState(true); // Loading state
    const { user } = useContext(RootContext);
    const { t } = useTranslation();

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true); // Start loading
            if (localStorage.getItem('hospitalSearchText')) {
                setSearchText(localStorage.getItem('hospitalSearchText'));
                localStorage.removeItem('hospitalSearchText');
            } else if (
                checkedInsurances.length === 0 &&
                checkedCategories.length === 0 &&
                checkedAddons.length === 0 &&
                hospitalTypes.length === 0 &&
                allSearchedHospitals.length === 0
            ) {
                await clearHospitalSearch();
            } else {
                await filterOutHospitals({
                    checkedInsurances,
                    checkedCategories,
                    checkedAddons,
                    hospitalTypes
                });
            }
            setLoading(false); // End loading when data is fetched
        };

        fetchData();
    }, []);

    const onInsuranceCheckboxChanged = async (spec) => {
        setLoading(true); // Start loading when a filter is changed
        if (checkedInsurances.filter(item => item === spec).length > 0) {
            insurancesFilter(checkedInsurances.filter(item => item !== spec));
            await filterOutHospitals({
                checkedInsurances: checkedInsurances.filter(item => item !== spec),
                checkedCategories,
                hospitalTypes,
                checkedAddons
            });
        } else {
            insurancesFilter([...checkedInsurances, spec]);
            await filterOutHospitals({
                checkedInsurances: [...checkedInsurances, spec],
                checkedCategories,
                hospitalTypes,
                checkedAddons
            });
        }
        setLoading(false); // End loading when filtering is done
    };

    const onCategoriesCheckboxChanged = async (spec) => {
        setLoading(true); // Start loading
        if (checkedCategories.filter(item => item === spec._id).length > 0) {
            categoriesFilter(checkedCategories.filter(item => item !== spec._id));
            await filterOutHospitals({
                checkedCategories: checkedCategories.filter(item => item !== spec._id),
                checkedInsurances,
                hospitalTypes,
                checkedAddons
            });
        } else {
            categoriesFilter([...checkedCategories, spec._id]);
            await filterOutHospitals({
                checkedCategories: [...checkedCategories, spec._id],
                checkedInsurances,
                hospitalTypes,
                checkedAddons
            });
        }
        setLoading(false); // End loading
    };

    const onAddonsCheckboxChanged = async (spec) => {
        setLoading(true); // Start loading
        if (checkedAddons.filter(item => item === spec._id).length > 0) {
            addonsFilter(checkedAddons.filter(item => item !== spec._id));
            await filterOutHospitals({
                checkedAddons: checkedAddons.filter(item => item !== spec._id),
                checkedInsurances,
                hospitalTypes,
                checkedCategories
            });
        } else {
            addonsFilter([...checkedAddons, spec._id]);
            await filterOutHospitals({
                checkedAddons: [...checkedAddons, spec._id],
                checkedInsurances,
                hospitalTypes,
                checkedCategories
            });
        }
        setLoading(false); // End loading
    };

    const onHospitalCheckboxChange = async (spec) => {
        setLoading(true); // Start loading
        if (hospitalTypes.filter(item => item === spec).length > 0) {
            hospitalTypesFilter(hospitalTypes.filter(item => item !== spec));
            await filterOutHospitals({
                checkedInsurances,
                checkedCategories,
                hospitalTypes: hospitalTypes.filter(item => item !== spec),
                checkedAddons
            });
        } else {
            hospitalTypesFilter([...hospitalTypes, spec]);
            await filterOutHospitals({
                checkedInsurances,
                checkedCategories,
                hospitalTypes: [...hospitalTypes, spec],
                checkedAddons
            });
        }
        setLoading(false); // End loading
    };

    const filterOutHospitals = async (filters) => {
        setLoading(true); // Start loading before filtering
        await filterHospitals(filters);
        setSearchText("");
        setLoading(false); // End loading after filtering
    };

    const onSearchHospital = async (e) => {
        e.preventDefault();
        setLoading(true); // Start loading on search
        if (searchText !== "") {
            await searchHospitalByText(searchText);
        }
        setLoading(false); // End loading after search
    };

    return (
        <AppLayout>
            <SearchHospital searchText={searchText} setSearchText={setSearchText} clearHospitalSearch={clearHospitalSearch} onSearchHospital={onSearchHospital} />

            <section className="user-dashboard search-filter-section">
                <div className="container">
                    <div className="row pb-5">
                        <div className="col-md-3 search-filter">
                            <div className="d-flex justify-content-between">
                                <h5 className="mb-2">{t('filters')}</h5>
                                <p className="text-gray mb-2" style={{ cursor: "pointer" }} onClick={clearHospitalSearch}>{t('reset_filters')}</p>
                            </div>
                            <hr className="mt-0" />
                            <form>
                                <HospitalTypeFilters onHospitalCheckboxChange={onHospitalCheckboxChange} hospitalTypes={hospitalTypes} />

                                <InsuranceFilters checkedInsurances={checkedInsurances} onInsuranceCheckboxChanged={onInsuranceCheckboxChanged} />

                                <CategoriesFilter checkedCategories={checkedCategories} onCategoriesCheckboxChanged={onCategoriesCheckboxChanged} />

                                <AddonsFilter checkedAddons={checkedAddons} onAddonsCheckboxChanged={onAddonsCheckboxChanged} />
                            </form>
                        </div>
                        <div className="col-md-9">
                            <div className="d-flex justify-content-between align-items-center mb-4 mt-4 mt-md-0">
                                <h5 className="text-primary mb-0">{allSearchedHospitals.length} {t("hospitals")}</h5>
                            </div>

                            {/* Display loader when loading is true */}
                            {loading ? (
                                <div className="d-flex justify-content-center">
                                    <FadeLoading />
                                </div>
                            ) : (
                                allSearchedHospitals.length > 0 && allSearchedHospitals?.map(hospital => {
                                    return <SearchedHospitals hospital={hospital} key={hospital._id} />;
                                })
                            )}
                        </div>
                    </div>
                </div>
            </section>
        </AppLayout>
    );
}

const mapStateToProps = (state) => ({
    searchedHospitals: state.searchedHospitals
});

const mapDispatchToProps = {
    filterHospitals,
    insurancesFilter,
    categoriesFilter,
    hospitalTypesFilter,
    addonsFilter,
    clearHospitalSearch,
    searchHospitalByText
};

export default connect(mapStateToProps, mapDispatchToProps)(Hospital);
