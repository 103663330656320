import React, { useContext, useState } from "react";
import PATIENT_IMAGE from "../../../../assets/images/patient.png";
import DOCTOR_IMAGE from "../../../../assets/images/doctor.png";
import LAB_IMAGE from "../../../../assets/images/lab.png";
import { getAge } from "../../../../Utills/functions";
import moment from "moment";
import GenerateQrCode from "./GenerateQrCode";
import { RootContext } from "../../../../contextApi";
import { BeatLoader } from "react-spinners";
import { useTranslation } from "react-i18next";
import QRCode from 'qrcode.react';
import CancelAppointment from "./CancelAppointment";
import UpdateProfile from "./UpdateProfile";
import EMPTY_IMAGE_PLACEHOLDER from "../../../../assets/images/doctor_placeholder.png";

function MedicalProfile({ patient, prescriptions }) {
  const { t } = useTranslation();
  const { user } = useContext(RootContext);
  const [selectedResult, setSelectedResult] = useState(null);
  const [selectedAAppointment, setSelectedAppointment] = useState({});

  return (
    <>
      <section className="user-dashboard patient-account">
        <div className="container">
          <div className="row pb-5">
            <div className="col-md-4">
              <div className="card profile-detail py-3">
                <div className="card-body">
                  <div className="media">
                    <img
                      className="patient-profile-large mr-0"
                      src={patient?.patient?.image || PATIENT_IMAGE}
                      alt="patient"
                    />
                    <div className="media-body">
                      {patient?.patient?.firstName ? (
                        <>
                          <h5 className="mt-3 mb-2">
                            {`${patient?.patient?.firstName} ${patient?.patient?.lastName}`}
                          </h5>
                          <h6>{`${t("age")}: ${getAge(patient?.patient?.birthday)}`}</h6>
                          <a
                            href="javascript:void(0)"
                            data-toggle="modal"
                            data-target="#updateVitals"
                            className="btn btn-primary px-3 py-1"
                          >
                            {t("update")}
                          </a>
                        </>
                      ) : (
                        <div style={{ marginTop: "1rem" }}>
                          <BeatLoader size={8} color="#417EBF" />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="card patient-detail">
                <div className="card-body">
                  <h5>
                    {t("information")}:
                    {!patient?.patient?.firstName && (
                      <span style={{ marginLeft: "1rem" }}>
                        <BeatLoader size={6} color="#417EBF" />
                      </span>
                    )}
                  </h5>
                  <ul>
                    <li><span>{t("gender")}:</span> {patient?.patient?.gender || "-"}</li>
                    <li><span>{t("blood_type")}:</span> {patient?.patient?.bloodType || "-"}</li>
                    <li>
                      <span>{t("allergies")}:</span> 
                      {patient?.patient?.allergies?.length === 0 ? "-" : patient?.patient?.allergies?.join(", ")}
                    </li>
                    <li>
                      <span>{t("diseases")}:</span>
                      {patient?.patient?.diseases?.length === 0 ? "-" : (
                        patient?.patient?.diseases?.join(", ").length > 25
                          ? `${patient?.patient?.diseases?.join(", ").slice(0, 25)}...`
                          : patient?.patient?.diseases?.join(", ")
                      )}
                    </li>
                    <li><span>{t("height")}:</span> {patient?.patient?.height ? `${patient?.patient?.height}cm` : "-"}</li>
                    <li><span>{t("weight")}:</span> {patient?.patient?.weight ? `${patient?.patient?.weight}kg` : "-"}</li>
                    <li><span>{t("points")}:</span> {user?.points}</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md-8">
              <div className="row">
                <div className="col-md-12">
                  <div className="card lab-result">
                    <div className="card-body">
                      <h5>{t("upcoming_appointment")}</h5>
                      {patient?.upcommingAppointments?.length > 0 ? (
                        patient?.upcommingAppointments?.map((appointment, index) => (
                          <div
                            key={index}
                            style={{
                              position: "relative",
                              padding: "1.5rem",
                              border: "1px solid lightgray",
                              borderRadius: "10px",
                              marginBottom: "1rem",
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center"
                            }}
                          >
                            {appointment?.serviceId && (
                              <div
                                style={{
                                  position: "absolute",
                                  top: "-10px",
                                  left: "50%",
                                  transform: "translateX(-50%)",
                                  backgroundColor: "#5a9bd4",
                                  color: "white",
                                  padding: "5px 10px",
                                  borderRadius: "5px",
                                  fontSize: "0.8rem",
                                  fontWeight: "bold"
                                }}
                              >
                                {t("Service Booked")}
                              </div>
                            )}
                            <div>
                              <small className="d-block">{`${t("date")} & ${t("time")}`}</small>
                              {`${moment(appointment?.from).format("DD-MM-YY")} - ( ${moment(appointment?.from).format("hh:mm a")} - ${moment(appointment?.to).format("hh:mm a")} )`}
                            </div>
                            <div className="media">
                              <img
                                className="avatar-sm"
                                src={appointment?.doctorId?.image || appointment?.nurseId?.image || DOCTOR_IMAGE}
                                alt="doctor"
                              />
                              <div className="media-body ml-2">
                                <h5 className="mt-0 mb-1">
                                  {appointment?.doctorId?.firstName ? (
                                    `Dr. ${appointment?.doctorId?.firstName} ${appointment?.doctorId?.lastName}`
                                  ) : (
                                    `${appointment?.nurseId?.firstName} ${appointment?.nurseId?.lastName}`
                                  )}
                                </h5>
                                <p>{appointment?.doctorId?.specialityId?.name}</p>
                              </div>
                            </div>
                            <div>
                              <small className="d-block">{t("hospital")}</small>
                              {appointment?.hospitalId?.name}
                            </div>
                            <div>
                              <a
                                href="javascript:void(0)"
                                onClick={(e) => {
                                  e.preventDefault();
                                  setSelectedAppointment(appointment);
                                }}
                                data-toggle="modal"
                                data-target="#cancel"
                                className="btn btn-danger px-3"
                              >
                                {t("CANCEL")}
                              </a>
                            </div>
                          </div>
                        ))
                      ) : (
                        <p style={{ color: "GrayText", marginTop: "20px" }}>
                          No appointments found
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 col-lg-12 pr-lg-1">
                  <div className="card lab-results">
                    <div className="card-body">
                      <h5 className="mb-0">{t("lab_results")}</h5>
                      {patient?.labResults?.length > 0 ? (
                        patient?.labResults?.map((labResult) => (
                          <div className="row" key={labResult._id}>
                            <div className="col-sm-12 col-md-6">
                              <div className="media">
                                <span>
                                  <img
                                    src={LAB_IMAGE}
                                    style={{
                                      width: "20px",
                                      height: "20px",
                                      borderRadius: "0px"
                                    }}
                                    alt="lab"
                                  />
                                </span>
                                <div className="media-body">
                                  <h5>{labResult?.tests?.map((item, index) =>
                                    index === labResult?.tests?.length - 1
                                      ? item.test
                                      : item.test + ", "
                                  )}</h5>
                                  <p>{moment(labResult?.date).format("LL")}</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))
                      ) : (
                        <p style={{ color: "GrayText", marginTop: "20px" }}>
                          No lab results found
                        </p>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-md-12 col-lg-12">
                  <div className="card lab-results pb-1">
                    <div className="card-body pb-4">
                      <h5 className="mb-4">{t("QR_Prescriprion")}</h5>
                      {prescriptions?.filter(app => app.serviceId === null)?.map((qr, index) => (
                        <div key={index} className="card lab-result">
                          <div className="card-body py-md-2">
                            <div className="row align-items-center">
                              <div className="col-md-12 col-lg-12">
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between"
                                  }}
                                >
                                  <div>
                                    <small className="d-block">{t("date")}</small>
                                    {moment(qr.to).format("LL")}
                                  </div>
                                  <div>
                                    <li className="media">
                                      <img
                                        className="avatar-sm"
                                        src={qr?.doctorId?.image || EMPTY_IMAGE_PLACEHOLDER}
                                        alt="doctor"
                                      />
                                      <div className="media-body ml-2">
                                        <h5 className="mt-0 mb-1">
                                          Dr. {`${qr?.doctorId?.firstName} ${qr?.doctorId?.lastName}`}
                                        </h5>
                                        <p>{qr?.doctorId?.specialityId?.name}</p>
                                      </div>
                                    </li>
                                  </div>
                                  {qr?.serviceId ? null : (
                                    <div>
                                      <li className="media">
                                        <a
                                          href="javascript:void(0)"
                                          data-toggle="modal"
                                          data-target="#qrCode"
                                          className="btn btn-primary px-3 py-2 mr-3"
                                          onClick={() => setSelectedResult(`${window.location.origin}/patient-report/${qr._id}`)}
                                        >
                                          {t("VIEW_QR")}
                                        </a>
                                        <div className="media-body ml-2">
                                          <a
                                            href={`/patient-report/${qr._id}`}
                                            target="_blank"
                                            className="px-3 py-2 mr-3"
                                          >
                                            {t("view_report")}
                                          </a>
                                        </div>
                                      </li>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                      {(!prescriptions || prescriptions?.length === 0) && (
                        <div
                          style={{
                            backgroundColor: "lightgray",
                            padding: "1rem",
                            borderRadius: "5px"
                          }}
                        >
                          {t("no_prescriptions_available_yet")} !
                        </div>
                      )}
                      <GenerateQrCode
                        selectedResult={selectedResult}
                        setSelectedResult={setSelectedResult}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <CancelAppointment selectedAppointment={selectedAAppointment} />
        <UpdateProfile patient={patient.patient} />
      </section>
    </>
  );
}

export default MedicalProfile;
